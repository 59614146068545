// src/utils/fetchChartData.js

import axios from 'axios';
import { calculateIndicators, detectMACDCrossover, generateAISentiment, combineAnalysis } from './indicators';

async function fetchChartData(chartType, timeframe, coin) {
  try {
    const endTime = Date.now();
    let startTime;

    // Adjust the time range based on the timeframe
    switch(timeframe) {
      case '1m':
      case '5m':
        startTime = endTime - (24 * 60 * 60 * 1000); // Last 24 hours
        break;
      case '15m':
      case '30m':
        startTime = endTime - (7 * 24 * 60 * 60 * 1000); // Last 7 days
        break;
      case '1h':
        startTime = endTime - (30 * 24 * 60 * 60 * 1000); // Last 30 days
        break;
      case '4h':
      case '1d':
        startTime = endTime - (90 * 24 * 60 * 60 * 1000); // Last 90 days
        break;
      default:
        startTime = endTime - (30 * 24 * 60 * 60 * 1000); // Default to 30 days
    }

    // Fetch meta data first
    const metaResponse = await axios.post('https://api.hyperliquid.xyz/info', {
      type: 'metaAndAssetCtxs'
    });

    // Extract coin stats from meta response
    const universe = metaResponse.data[0].universe;
    const assetCtxs = metaResponse.data[1];
    const coinIndex = universe.findIndex(asset => asset.name === coin);

    if (coinIndex === -1) {
      throw new Error(`Coin ${coin} not found in universe`);
    }

    const coinStats = {
      szDecimals: universe[coinIndex].szDecimals,
      isSpot: false, // Assuming all assets are perps, update this if needed
      actualDecimals: (assetCtxs[coinIndex].markPx.toString().split('.')[1] || '').length,
    };

    // Fetch candle data
    const candleResponse = await axios.post('https://api.hyperliquid.xyz/info', {
      type: 'candleSnapshot',
      req: { coin, interval: timeframe, startTime, endTime }
    });

    //console.log('Candle response:', candleResponse.data);

    if (!Array.isArray(candleResponse.data) || candleResponse.data.length === 0) {
      throw new Error('Invalid or empty candle data received');
    }

    const candleData = candleResponse.data.map(candle => ({
      time: Math.floor(candle.t / 1000),
      open: parseFloat(candle.o),
      high: parseFloat(candle.h),
      low: parseFloat(candle.l),
      close: parseFloat(candle.c),
      volume: parseFloat(candle.v)
    })).sort((a, b) => a.time - b.time);

    // For now, let's skip orderbook and recent trades to isolate the issue
    const orderBook = { bids: [], asks: [] };
    const recentTrades = [];

    let indicators = {};
    try {
      indicators = calculateIndicators(candleData, coinStats.szDecimals, coinStats.isSpot);
    } catch (error) {
      console.warn('Error calculating indicators:', error);
      // Set indicators to an empty object if calculation fails
      indicators = {};
    }
    const macdCrossover = detectMACDCrossover(indicators.macd, candleData, timeframe);
    const aiSentiment = generateAISentiment(candleData, indicators, coinStats, orderBook, recentTrades, timeframe);
    const combinedAnalysis = combineAnalysis(indicators, null, candleData);

    // Extract volume data
    const volume = candleData.map(candle => candle.volume);

    // Perform market assessment only if we have valid indicator data
    let marketAssessment = "Unable to assess market due to insufficient data.";
    if (Object.keys(indicators).length > 0 && candleData.length > 0) {
      marketAssessment = assessMarket(indicators, candleData[candleData.length - 1].close);
    }

    return {
      chartType,
      timeframe,
      coin,
      candleData,
      orderBook,
      recentTrades,
      indicators,
      aiSentiment,
      marketAssessment,
      combinedAnalysis,
      macdCrossover,
      coinStats,
      volume
    };

  } catch (error) {
    console.error('Error fetching chart data:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw new Error(`Failed to fetch chart data: ${error.message}`);
  }
}

function getTimeframeInMilliseconds(tf) {
  const units = {
    'm': 60 * 1000,
    'h': 60 * 60 * 1000,
    'd': 24 * 60 * 60 * 1000
  };
  const [value, unit] = tf.match(/(\d+)(\w)/).slice(1);
  return parseInt(value) * units[unit];
}

function assessMarket(indicators, currentPrice) {
  let bullishPoints = 0;
  let bearishPoints = 0;

  // Safely get the last value from an array
  const getLastValue = (arr) => arr && arr.length > 0 ? arr[arr.length - 1].value : null;

  // RSI
  const lastRSI = getLastValue(indicators.rsi);
  if (lastRSI !== null) {
    if (lastRSI < 30) bullishPoints++;
    if (lastRSI > 70) bearishPoints++;
  }

  // MACD
  const latestMACD = getLastValue(indicators.macd?.macdLine);
  const latestSignal = getLastValue(indicators.macd?.signalLine);
  if (latestMACD !== null && latestSignal !== null) {
    if (latestMACD > latestSignal) bullishPoints++;
    if (latestMACD < latestSignal) bearishPoints++;
  }

  // Bollinger Bands
  const latestBB = indicators.bollingerBands && indicators.bollingerBands[indicators.bollingerBands.length - 1];
  if (latestBB && latestBB.lower !== undefined && latestBB.upper !== undefined) {
    if (currentPrice < latestBB.lower) bullishPoints++;
    if (currentPrice > latestBB.upper) bearishPoints++;
  }

  // Moving Averages
  const ma50 = getLastValue(indicators.ma?.ma50);
  const ma200 = getLastValue(indicators.ma?.ma200);
  if (ma50 !== null && ma200 !== null) {
    if (currentPrice > ma50 && currentPrice > ma200) bullishPoints++;
    if (currentPrice < ma50 && currentPrice < ma200) bearishPoints++;
  }

  // DMI
  const latestADX = getLastValue(indicators.dmi?.adx);
  const latestDIPlus = getLastValue(indicators.dmi?.diPlus);
  const latestDIMinus = getLastValue(indicators.dmi?.diMinus);
  if (latestADX !== null && latestADX > 25) {
    if (latestDIPlus !== null && latestDIMinus !== null) {
      if (latestDIPlus > latestDIMinus) bullishPoints++;
      if (latestDIMinus > latestDIPlus) bearishPoints++;
    }
  }

  if (bullishPoints > bearishPoints) {
    return "This might be a good time to buy. Multiple indicators are showing bullish signals.";
  } else if (bearishPoints > bullishPoints) {
    return "This could be a good time to sell. Multiple indicators are showing bearish signals.";
  } else {
    return "The market is currently showing mixed signals. It might be best to wait for clearer indications before making a decision.";
  }
}

export default fetchChartData;